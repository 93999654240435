import { render, staticRenderFns } from "./details.vue?vue&type=template&id=93f1eee2&scoped=true&"
import script from "./details.vue?vue&type=script&lang=js&"
export * from "./details.vue?vue&type=script&lang=js&"
import style0 from "./details.vue?vue&type=style&index=0&id=93f1eee2&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "93f1eee2",
  null
  
)

export default component.exports